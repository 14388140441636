<template>
  <div class="results-view container">
    <div class="logo"><router-link to="/"><img src="@/assets/images/storesafe-logo.png" width="100%" /></router-link></div>
    <div v-if="isLoading">Loading...</div>
    <AssessmentResults 
      v-if="assessment && assessment.answers && questions.length"
      :answers="assessment.answers" 
      :questions="questions"
      :currentQuestionIndex="assessment.currentQuestionIndex"
      :assessmentTitle="assessment.title"
      @continue-assessment="continueAssessment"
    />
    <div v-else>Failed to load assessment data.</div>
  </div>
</template>

<script>
import AssessmentResults from '@/components/AssessmentResults.vue'
import axios from 'axios';

export default {
  components: {
    AssessmentResults
  },
  props: ['id'],
  data() {
    return {
      assessment: null,
      question: [],
      isLoading: true
    }
  },
  methods: {
    continueAssessment() {
      console.log('Continuing assessment');
      this.$router.push({ name: 'Assessment', params: { id: this.assessment.id } });
    }
  },
  async created() {
    try {
      const [assessmentResponse, questionsResponse] = await Promise.all([
        axios.get(`https://storesafe.fyi/assessment/${this.id}`),
        axios.get('https://storesafe.fyi/questions')
      ]);
      this.assessment = assessmentResponse.data;
      this.questions = questionsResponse.data;
      console.log('Assessment:', this.assessment);
      console.log('Questions:', this.questions);
      console.log('Answers:', this.assessment.answers);
      console.log('Number of answered questions:', Object.keys(this.assessment.answers).length);
      console.log('Number of total questions:', this.questions.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      this.isLoading = false;
    }
  }
}
</script>

