<template>
  <div class="assessment-results">
    <h1 class="assessment-title">{{ assessmentTitle }}</h1>
    <h2 style="text-align: center;width:100%;">Assessment Results</h2>
    <div class="total-score">
      <ResultsRing
        category="Total Score"
        :score="totalScore"
        :size="300"
        :weights="questionWeights"
      />
    </div>
    <p v-if="!isComplete">Note: This assessment is incomplete. Results are based on answered questions only.</p>
    <div class="results-grid">
      <ResultsRing
        v-for="category in categories"
        :key="category"
        :category="category"
        :score="getCategoryScore(category)"
        :weights="questionWeights"
      />
    </div>
    <button @click="continueAssessment" class="btn-start">Continue This Assessment</button>
    <button @click="downloadPDF" class="download-button">Download Assessment as PDF</button>
  </div>
</template>

<script>
import ResultsRing from './ResultsRing.vue';
import html2pdf from 'html2pdf.js';

export default {
  components: {
    ResultsRing
  },
  props: ['answers', 'questions', 'currentQuestionIndex', 'assessmentTitle'],
  data() {
    return {
      categories: ['Food Safety', 'Space Optimization', 'Cost Savings', 'Labor Efficiency', 'Sustainability']
    };
  },
  created() {
    console.log('AssessmentResults created');
    console.log('Answers:', this.answers);
    console.log('Questions:', this.questions);
    console.log('Current Question Index:', this.currentQuestionIndex);
    console.log('Categories:', this.categories);
  },
  computed: {
    isComplete() {
      return this.questions.every(q => this.answers[q.id] !== undefined);
    },
    totalScore() {
      const scores = this.categories.map(category => this.getCategoryScore(category));
      return scores.reduce((a, b) => a + b, 0) / this.categories.length;
    },
    questionWeights() {
      return this.questions.reduce((acc, question) => {
        this.categories.forEach(category => {
          const weightKey = `weight${category.replace(/\s+/g, '')}`;
          acc[weightKey] = (acc[weightKey] || 0) + (question[weightKey] || 0);
        });
        return acc;
      }, {});
    }
  },
  methods: {
    getCategoryScore(category) {
      console.log(`Calculating score for ${category}`);
      const weightKey = `weight${category.replace(/\s+/g, '')}`;
      const relevantQuestions = this.questions.filter(q => q[weightKey] > 0);
      console.log(`${category} relevant questions:`, relevantQuestions.length);
      if (relevantQuestions.length === 0) {
        console.log(`No questions found for ${category}`);
        return 0;
      }
      const totalWeight = relevantQuestions.reduce((sum, q) => sum + q[weightKey], 0);
      const weightedScore = relevantQuestions.reduce((score, q) => {
        const answer = this.answers[q.id];
        console.log(`Question ${q.id} answer:`, answer);
        return score + (answer ? q[weightKey] : 0);
      }, 0);
      const score = (weightedScore / totalWeight) * 100;
      console.log(`${category} score:`, score);
      return score;
    },
      continueAssessment() {
      console.log('Emitting continue-assessment event');
      this.$emit('continue-assessment');
    },
    downloadPDF() {
      console.log('Attempting to download PDF');
      const element = document.querySelector('.assessment-results');
      const opt = {
        margin: 10,
        filename: 'assessment_results.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };

      html2pdf().from(element).set(opt).save()
        .then(() => {
          console.log('PDF downloaded successfully');
        })
        .catch(error => {
          console.error('Error generating PDF:', error);
        });
    }
  }
};
</script>