// src/authClient.js

import axios from 'axios';

export default {
  setUserData(userData) {
    localStorage.setItem('user', JSON.stringify(userData));
    axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
  },
  clearUserData() {
    localStorage.removeItem('user');
    delete axios.defaults.headers.common['Authorization'];
  },
  getUserData() {
    const userString = localStorage.getItem('user');
    return userString ? JSON.parse(userString) : null;
  },
  isAuthenticated() {
    return !!this.getUserData();
  }
};